import * as React from "react"
import { Link } from "gatsby";
import { HeaderContainer } from "../containers/HeaderContainer";
import { Menu } from "./Menu";

const Header = ({ slug }) => {
  
  return (
    <HeaderContainer>
      <h1>
        <Link to="/">Marlo Disch</Link>
      </h1>
      <Menu slug={slug} />
    </HeaderContainer>
  )
}

export default Header